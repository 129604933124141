import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { usePolicyPages } from '@helpers-blog'
import {graphql} from 'gatsby'
import { useTranslation } from "react-i18next"
import { Link as GLink } from 'gatsby'
import {  Link } from 'theme-ui'


const {localizedUrl} = require("../../../../../site/src/util")

const PagePolicies = props => {
  const policies = usePolicyPages()
  const {t, i18n} = useTranslation()
  
  return (
    <Layout {...props}>
      <Seo title={t('Policies')} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
      
        <PageTitle
            header={t('Policies')}
            // subheader='FlexiBlog theme comes with a pre-made contact form component. You can integrate this form with serverless services such as Formspree, Getform, FormKeep and others to receive form submissions via email.'
          />

        
      </Stack>
      <Stack>
        <Main>
          {policies.map((policy, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <Link variant='mute' as={GLink} to={localizedUrl({url: `/policy/${policy.slug}`, locale: i18n.language})}>
                <PageTitle header={policy.title}  />
              </Link>
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }`  

export default PagePolicies
